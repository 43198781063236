
import "./assets/css/style.css";
import { ToastContainer } from 'react-toastify';
import { createContext, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';


import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Search from './compoments/pages/Search';
import Home from './compoments/pages/Home';
import ThankYou from "./compoments/pages/ThankYou";
import TokenHelper from "./services/TokenHelper";
import { LoginAuth } from './compoments/pages/LoginAuth';
import { LogoutAuth } from './compoments/pages/LogoutAuth';

export const AuthContext = createContext();
var initialValue = {}
function App() {
  const [tokendata,setToken]=useState(TokenHelper.getToken()?TokenHelper.getToken():"");
  const [userInfo,setUserInfo]=useState(TokenHelper.getToken()?TokenHelper.getUserInfo():{})
  initialValue.tokendata=tokendata;
  initialValue.setToken=setToken;
  initialValue.userInfo=userInfo;
  initialValue.setUserInfo=setUserInfo;
  return (
    <>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
    />
    <div className="App">
    <AuthContext.Provider value={{ user: initialValue }} >
      <BrowserRouter>
            <Routes>
                <Route path='/' element={<LogoutAuth><Home/></LogoutAuth>} />
                <Route path='/search' element={<LoginAuth><Search/></LoginAuth>} />
                <Route path='/thankyou' element={<LoginAuth><ThankYou/></LoginAuth>} />

            </Routes>
         </BrowserRouter>
         </AuthContext.Provider>
    </div>
    </>
  );
}

export default App;
