import * as yup from "yup";

  export const UserTokenSchema = yup.object({ 
    token: yup.string().required("Pass Code Required"),
   
  })
  export const UserProductSchema = yup.object({ 
    product_code: yup.string().required("Product Code is Required"),
   
  })
  