import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from 'react-bootstrap/Modal';
// import "react-bootstrap-tagsinput/dist/index.css";
import { InputTags } from "react-bootstrap-tagsinput";
import UserService from '../../services/User.Services';
import { UserProductSchema } from '../pages/Schema';
import TokenHelper from '../../services/TokenHelper';
import { useContext } from 'react';
import { AuthContext } from '../../App';

import { FaPlusCircle } from 'react-icons/fa';
import DownloadPdf from '../../assets/images/download-pdf.png';
import ThankyouLogo from '../../assets/images/thankyou.png';
import IconCloseSmall from '../../assets/images/icon-close-small.png';
import TopLogo from '../../assets/images/top-logo.png';
import Loader from "../pages/Loder";
import { saveAs } from "file-saver";




function Home() {
  var navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [state, setState] = useState("");
  const [loaderdata, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState();
  const [downloadEnabled, setDownloadEnabled] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isSyncing2, setIsSyncing2] = useState(true);
  const { user } = useContext(AuthContext);
  const [isSyncingMsg, setiIsSyncingMsg] = useState("Sync with Shopify Store")
  const [mainLoader, setMainLoader] = useState({
    loader: false,
    msg: false
  });

  const [show, setShow] = useState(false);
  const [productcodevalue, setProductcodevalue] = useState('');

  const { control, register, handleSubmit, reset, formState: { errors }, watch } = useForm({
    resolver: yupResolver(UserProductSchema),
    mode: "all"
  });
  // const isDisabled = true;
  const handleInputChange = (event) => {
    var st = event.target.value
    let array1 = st.split(' ');


    setFormData(array1);
    setProductcodevalue(event.target.value);

  };


  const dataSubmit = async () => {
    setLoader(true);
    // alert(data)
    console.log(productcodevalue)
    console.log(formData)
    
    var newArray=formData.filter((i)=>i!="");
    var newProductCodeValue=newArray.join(' ');
    // console.log("newArray ", newProductCodeValue);




    // console.log(data)

    var fdata = new FormData();

    fdata.append("product_code", newProductCodeValue);

    var response = await UserService.PostProductId(fdata);

    if (response.data.error) {
      setLoader(false);
      toast.error(response.data.error.usererror)
    }
    if (response.data.status) {
      setLoader(false);
      console.log(response.data)
      setDownloadEnabled(true);
      toast.success(response.data.message)
      var alldata = response.data.filedata
      setState(alldata)
    } else {
      setLoader(false);
      toast.error(response.data.message)
      setDownloadEnabled(false);
      setState("")
    }
    reset();


  }


  const downloadFile = (url) => {
    // if(downloadEnabled){
    setDownloadEnabled(false)
    const uniqueSuffix = Date.now() + '-' + Math.round(Math.random() * 1e9);
    var filename = `order-list-${uniqueSuffix}.pdf`;
    saveAs(url, filename)
    toast.success('Download  Successful')
    // window.location.href = url;
    // }
  }

  const logout = () => {
    localStorage.clear();
    navigate("/")
    toast.success('Logout Successfully')
  };
  const handleClick = (item) => {
    const filteredItems = formData.filter((i) => i !== item );
    setFormData(filteredItems);
    setProductcodevalue(filteredItems);
  };
  const handleSync = async () => {
    var response = await UserService.PostSyncData();

    if (response.data.status) {
      console.log(response.data)
      setIsSyncing2(false)
      setIsSyncing(true);
      setMainLoader({ ...mainLoader, "loader": false, msg: true })
    } else {
      setIsSyncing(false);
      setMainLoader({ ...mainLoader, "loader": false, msg: false })
    }

    // Simulating a delay for demonstration purposes
    setTimeout(() => {
      setMainLoader({ ...mainLoader, "loader": false, msg: false })
    }, 2000);
  };


  return (
    <>
      {loaderdata && <Loader />}
      <div className='wrapper-sec'>
        <Container>
          <div className="d-flex pt-4">
            <div className="top-logo">
              <a href="/"><img src={TopLogo} alt="" /></a>
            </div>
            <div className="right-btn">

              <Button onClick={logout} className="logout-btn">Logout</Button>
            </div>
          </div>
          <div>



            {isSyncing2 && <>
              <button className='sync-btn' onClick={() => {
                setMainLoader({ ...mainLoader, "loader": true })
                // setiIsSyncingMsg("Syncing...")
                handleSync()
              }}>
                {isSyncingMsg}
              </button>

              {mainLoader.loader && <><div className='loading'><div className="loader">
                <div className="sync"></div>
                <p style={{ "color": "#FDAD2A" }}>Syncing</p>
              </div></div></>}

            </>}

            {mainLoader.msg && <>
              {/* <p>{isSyncingMsg}</p> */}
              <div className='loading'>
                <div className="tick">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                    <circle className="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                    <polyline className="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                  </svg>
                  <p className="success">Synced Succesfully</p>
                </div>
              </div>
            </>}

            {/* <div className='loading'>
       <div className="loader">
        <div className="sync"></div>
        <p style={{"color":"#FDAD2A"}}>Syncing</p>
      </div>
      <div className="tick">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
        <circle className="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
      <polyline className="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
      </svg>
      <p className="success">Synced Succesfully</p>
      </div> 
    </div> */}


          </div>
          <Row className="search-section">

            <Col xs={12} md={6}>
              {isSyncing && <>
                <h1 className="text-center mb-4">Please Enter Order Number</h1>
              </>}
              {!isSyncing && <>
                <h1 className="text-center headactive mb-4">Please Enter Order Number</h1>
              </>}
              <Form onSubmit={handleSubmit(dataSubmit)}>
                <Form.Group className="d-flex">
                  <Form.Control disabled={!isSyncing} type="text" value={productcodevalue} {...register("product_code")} onChange={handleInputChange} placeholder="Enter Order Number" />
                  <p className='form-field-error' style={{ color: 'red' }}>{errors.product_code?.message}</p>

                  {isSyncing && <>
                    <Button type="submit" variant="primary" className="search-btn" >
                      Submit
                    </Button> </>}

                  {!isSyncing && <>
                    <Button disabled variant="primary" className="search-btn2" >
                      Submit
                    </Button> </>}

                </Form.Group>
              </Form>
              <div>
                <ul>
                  {formData.length > 0 && <>
                    {formData.map((item) => (
                      <li key={item}>
                        {item}
                        <a onClick={() => handleClick(item)}>
                          <img src={IconCloseSmall} alt="" style={{ "width": "20px", "height": "20px" }} />
                        </a>
                      </li>
                    ))}
                  </>}
                </ul>
              </div>
              <div className="download-btn">

                {downloadEnabled ? <>
                  <Button onClick={() => downloadFile(state)} variant="success" className="login-btn" >
                    <img src={DownloadPdf} alt="Download PDF" className="mr-2" />
                    DOWNLOAD
                  </Button>

                </> : <>

                  <Button disabled variant="success" className="login-btn-2" >
                    <img src={DownloadPdf} alt="Download PDF" className="mr-2" />
                    DOWNLOAD
                  </Button>

                </>}

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Home