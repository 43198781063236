import React from 'react'

function Loader() {
  return (
         <div className="bg-loader">
	        <div className="loaderdata"></div>
        </div>
  )
    
  
}

export default Loader