import axios from "axios";

import TokenHelper from './TokenHelper';

const REACT_APP_API_SERVICE_URL = process.env.REACT_APP_API_SERVICE_URL;

class UserTokenService {


  PostToken(data) {
    return axios.post(REACT_APP_API_SERVICE_URL + '/token', data, TokenHelper.getHeader());
  }
  PostProductId(data) {
    return axios.post(REACT_APP_API_SERVICE_URL + '/product', data, TokenHelper.getHeader());

  }
  PostSyncData() {
    return axios.post(REACT_APP_API_SERVICE_URL + '/totalorder', TokenHelper.getHeader());

  }

}

export default new UserTokenService();