import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from 'react-bootstrap/Modal';
// import "react-bootstrap-tagsinput/dist/index.css";
import { InputTags } from "react-bootstrap-tagsinput";
import { UserProductSchema } from '../pages/Schema';


import { FaPlusCircle } from 'react-icons/fa';
import DownloadPdf from '../../assets/images/download-pdf.png';
import ThankyouLogo from '../../assets/images/thankyou.png';



function ThankYou() {

  return (
    <>
      <Container>
      <div id="myModal" class="modal">
		<div class="modal-content text-center">
            <img src={ThankyouLogo} alt="" className="d-block mx-auto" />
			<h1>THANK YOU</h1>
			<h3>FOR DOWNLOADING</h3>
			<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut. labore et dolore</p>
		</div>
	</div>
      </Container>

    </>
  )
}

export default ThankYou