import React from 'react';
import { Link,useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import UserService from '../../services/User.Services';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import CardsLogo from '../../assets/images/logo.png';
import TokenHelper  from '../../services/TokenHelper';
import { UserTokenSchema } from '../pages/Schema';
import { useContext } from 'react';
import { AuthContext } from '../../App';

function Home() {
  var navigate=useNavigate();
  const {user}=useContext(AuthContext);
  const { control,register, handleSubmit,reset , formState:{ errors },watch } = useForm({
    resolver: yupResolver(UserTokenSchema),
    mode:"all"
  });
  const dataSubmit=async(data) =>{
   
    var fdata = new FormData();
    fdata.append("token", data.token);
    var response=await UserService.PostToken(fdata);
   
    if(response.data.error)
    {
      toast.error('Invalid pass code')
    }
    if(response.data.status)
    {
      TokenHelper.setToken(response.data.tokendata)
      TokenHelper.setUserInfo(response.data.data)
      user.setToken(response.data.tokendata)
      user.setUserInfo(response.data.data)
      navigate("/search",{state:{data:response.data}})
      toast.success('Login success')
    }
    reset();

  
  }
  return (
    <>
      <section className="banner">
       
          <Row className='inner-banner'>
            <Col md={{ span: 6, offset: 3 }}>
              <div className="login-card text-center">
                <img src={CardsLogo} alt="" className="d-block mx-auto" />
                {/* <h2>Welcome to Cards Plug</h2> */}
                <h2>Welcome to CardsPlug's Dashboard</h2>
                <Form style={{width: '100%'}}  onSubmit={handleSubmit(dataSubmit)}>
                  <Form.Group controlId="formCode">
                    <Form.Control type="password" {...register("token")} placeholder="Enter Code" />
                    <p className='form-field-error' style={{color: 'red'}}>{errors.token?.message}</p>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="login-btn">
                    LOGIN
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
       
      </section>


    </>
  )
}

export default Home